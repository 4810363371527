<span *ngIf="adsHomePageType==1" (click)="close()" style="padding: 0px ; HEIGHT: 0PX;margin: 0px;" mat-dialog-title>
  <span (click)="close()" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </span>
</span>


<div *ngIf="adsHomePageType==1">
  <mat-dialog-content style=" margin: 0px;  max-height: 100%;
  padding: 0px;">
  
  <div fxFlex="3">
    
  </div>
  
  <div fxLayout="column" fxFlex="40" fxLayoutAlign="center center"> 
    
    <div class="logo padding-10">
      <img [src]="serverPath1+'logo.png'"  onerror="this.src='assets/images/error/logoerror1.png';" alt="logo" width="80" fxFlex="100">
    </div>
   
  
    <div class="title"  fxLayout="column">
      <h2 class="banner-title">{{adsHomePageTitle}}</h2>
    </div>
  
    <div class="description">
      <p>{{adsHomePageDescription}}</p>
    </div>
  
    <form [formGroup]="firstFormGroup" class="subscribe-search-form container" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4"
    ngClass.sm="mt-2" ngClass.xs="mt-2">
    
    <div fxLayout="column" fxLayout.gt-sm="row" fxFlex>
      <input class="text" formControlName="subscriberEmail" placeholder="Your Email address..." fxFlex>
      <button [disabled]="firstFormGroup.invalid" [class.disabled]="firstFormGroup.invalid" mat-raised-button color="accent" (click)="subscribe()" type="button"
        class="mat-elevation-z0 text-muted">Subscribe</button>
    </div>
  
    <mat-error *ngIf="firstFormGroup.hasError('subscriberEmail')" class="mt-2">Please enter a valid email address.</mat-error>
  </form>
  </div>
  
  <div fxFlex="2">
    
  </div>
  
  <div fxFlex>
    <img [src]="serverPath + adsHomePageImage" fxFlex="100" width="100%" [alt]="serverPath + adsHomePageImage" />
    <!-- <button fxLayoutAlign="end center" class="clrbg close padding-10" (click)="closePopup()" matDialogClose="yes" mat-icon-button>
      <mat-icon style="color: black;" >close</mat-icon>
    </button> -->
  </div>
  
  </mat-dialog-content>
</div>

<div class="dialog-container" *ngIf="adsHomePageType==0">

  <span  class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </span>

  <mat-dialog-content class="dialog-content">
  
    <div  [attr.data-tf-live]="typeFormIdHomePageTitle">
    </div>
  </mat-dialog-content>
</div>